#control-bar {
    position: fixed;
    top: 0;
    right: 0;
    bottom: -1px;
    transition: right 0.3s ease;
    min-height: 100%;
    z-index: 2;
    background: #f5f5f5;
    border-left: 1px solid #AAA;
}

#control-bar a {
    color: #154D84;
}

#control-bar a:hover {
    color: #0B2947;
}

#control-bar .header {
    padding: 20px;
    border-bottom: 1px solid #AAA;
    font-size: inherit;
    line-height: 0;
}

#control-bar + .handle {
    position: fixed;
    z-index: 10;
    top: 8px;
    right: 20px;
    cursor: pointer;
}

#control-bar > * {
    display: flex;
    flex-flow: column;
    position: absolute;
    top: 0; right: 0; left: 0;
    height: 100%;
    overflow-y: auto;
    z-index: 1;
}
#control-bar > *.panel {
    top: -100%;
    transition: top 0.3s ease;
}
#control-bar > *.panel,
#control-bar > *.panel h4 span {
    background: #dcd5d5;
}
#control-bar > *.panel.in {
    top: 0px;
}
#control-bar > *.panel >*:not(.header) {
    padding: 5px;
}

#control-bar fieldset {
    flex: 0 0 5rem;
    transition: flex-grow 0.3s;
}

#control-bar fieldset.current {
    border-bottom: 1px solid #AAA;
    flex: 1 0 auto;
}

#control-bar fieldset legend {
    margin: 0;
}
#control-bar fieldset legend > a,
#control-bar fieldset legend > a:active,
#control-bar fieldset legend > a:visited,
#control-bar fieldset legend > a:hover {
    display: block;
    height: 5rem;
    line-height: 5rem;
    margin: 0;
    text-align: center;
    border-bottom: 1px solid #AAA;
    background: #EEE;
    color: inherit;
    text-decoration: none;
}

#control-bar fieldset.current > legend > a {
    background: #CCC;
}

#control-bar fieldset > legend + * {
    display: none;
    margin-top: .5rem;
    padding: 5px;
}
#control-bar fieldset.current > legend + * {
    display: block;
}


@media (max-width: 768px) {
    #control-bar {
        right: -100%;
        width: 100%;
    }

    #control-bar.in {
        right: 0;
        width: 100%;
    }
}

@media (min-width: 768px) {
    #control-bar {
        right: -25rem;
        width: 25rem;
    }

    #control-bar.in {
        right: 0;
        width: 25rem;
    }
}

#control-bar h4 {
    height: 0.8rem;
    overflow: visible;
    margin-top: 1rem;
    margin-bottom: 1.35rem;
    clear: both;
    font-size: 1rem;
    border-bottom: 1px solid #AAA;
}
#control-bar h4 span {
    position: relative;
    background: #f5f5f5;
    margin-left: 0.7rem;
    padding: 0 0.3rem;
}

#control-bar input[type=radio],
#control-bar input[type=checkbox] {
    margin-right: 5px;
    vertical-align: middle;
}
#control-bar input[type=radio]:last-child,
#control-bar input[type=checkbox]:last-child {
    margin-left: 5px;
}

#control-bar .conc-type {
    margin: 0 1em;
}
#control-bar .conc-type > *:first-child { display: inline-block; float: left; }
#control-bar .conc-type > *:last-child { display: inline-block; float: right; }

#control-bar label {
    display: block;
}
#control-bar select {
    width: 100%;
}
#control-bar .noUi-horizontal {
    margin: 0 16px 3rem 16px;
}
#control-bar .noUi-horizontal .noUi-value-horizontal {
    top: 1rem;
    margin-right: -0.4rem;
}

#control-bar input[type=number],
#control-bar input[type=text] {
    width: 100%;
    padding: 4px 5px;
    margin-bottom: 0.2rem;
    border: 1px solid #aaa;
    background-color: #fff;
    background-image: linear-gradient(#eee 1%, #fff 15%);
}

#control-bar input[type=nouislider]:disabled + .noUi-target {
    display: none;
}

#control-bar .tag-list {
    height: 15em;
    overflow-y: scroll;
    margin: 1em 0;
    padding: 0;
    border: 1px solid #aaa;
    background-color: #fff;
    background-image: linear-gradient(#eee 1%, #fff 15%);
    user-select: none; -ms-user-select: none; -moz-user-select: none; -khtml-user-select: none; -webkit-user-select: none; -webkit-touch-callout: none;
    cursor: pointer;
}
#control-bar .tag-list > li {
    padding: 4px 5px;
    list-style-type: none;
}
#control-bar .tag-list > li.selected:active,
#control-bar .tag-list > li.selected {
    outline: none;
    background: yellow;
}
