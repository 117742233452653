html {
    font-size: 80%;
}

#scrollable-body {
    position: absolute;
    top: 0; right: 0; left: 0; bottom: 0;
    min-height: 100%;
    overflow-y: scroll;
    margin-right: 0;
    transition: margin-right 0.3s ease;
}
@keyframes spinner { to {transform: rotate(360deg);} }
body.loading #scrollable-body {
    opacity: .6;
}
body.loading #scrollable-body:after {
  content: '';
  box-sizing: border-box;
  position: fixed;
  z-index: 100;
  top: 50%;
  left: 50%;
  width: 20vw;
  height: 20vw;
  margin-top: -10vw;
  margin-left: -10vw;
  border-radius: 50%;
  border: 5px solid #ccc;
  border-top-color: #333;
  animation: spinner .6s linear infinite;
}
@media (min-width: 768px) {
  body.loading #scrollable-body:after {
      margin-left: -20rem;
  }
}

@media (min-width: 960px) {
    #control-bar.in ~ #scrollable-body {
        margin-right: 25rem;
    }
}

header {
    padding: 15px 25px 10px 15px;
    border-bottom: 5px solid #1e345e;
    overflow: hidden;
}
header h1 {
    float: left;
    margin: 0;
    line-height: 1.3rem;
    font-size: 41px;
    font-weight: normal;
}
header, header h1 a, header, header h1 a:link, header h1 a:visited {
    color: #1e345e;
}
header h1 a:hover, header h1 a:active {
    color: #ffa033;
    text-shadow: 0 0 3px #ffce98;
    text-decoration: none;
}
header h1 small {
    display: block;
    line-height: 1.4rem;
    font-size: .8rem;
    text-align: right;
    color: #444;
}
header .sponsors {
    float: right;
}
header .sponsors a img {
    height: 35px;
    margin-right: 2rem;
}
header .sponsors a:hover {
    text-decoration: none;
}
#sponsors-uob {
    position: relative;
    top: 8px;
}

#alerts {
    transform: scaleY(0);
    transform-origin: top;
}
#alerts:not(:empty) {
    transition: transform ease-in 0.1s;
    transform: scaleY(1);
}
#alerts > * {
    padding: 10px;
    font-weight: bold;
}
#alerts > .level-error { background: orangered; }
#alerts > .level-warn { background: #f0ad4e; }
#alerts > .level-info { background: #5bc0de; }
#alerts pre { font-size: xx-small; }
#alerts pre:empty { display: none; }

#content > .clic-contents {
    animation: fadein 2s;
    animation-timing-function: ease-in;
    padding: 2rem;
    max-width: 960px;
    margin: 0 auto;
    font-family: Georgia, Helvetica, serif;
    font-size: 120%;
}
#content > .clic-contents .first-sentence {
    color: darkred;
}
#content > .clic-contents .first-letter {
    float: left;
    font-size: 300%;
    line-height: 0.9em;
    padding: 3px 3px 0 0;
    color: darkred;
}
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
