.carousel {
    position: relative;
    height: 0;
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
    padding-bottom: 40%; /* i.e. carousel has fixed ratio dimensions */
    overflow: hidden;
}
.carousel li {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;

    background: #a5b7c5;
    background-size: cover;

    list-style-type: none;
}
.carousel li.offscreen {
    transition: left .5s ease-in-out;
    left: 100%;
}

.carousel li a, .carousel li a:link .carousel li a:visited {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0rem;
    font-family: Georgia,Helvetica,serif;
    line-height: normal;
    color: ivory;
    background: rgba(0,0,0,0.6);
    padding: 1rem;
}
.carousel li a:active, .carousel li a:hover, .carousel li a:focus {
    text-shadow: 0 0 5px rgba(255,255,255,0.5);
    text-decoration: none;
}

.carousel li a .carousel-title {
    margin-bottom: 0!important;
    font-size: 100%;
    display: block;
    font-weight: bold;
}

.carousel li a strong {
    font-size: 110%;
    color: #fdf5b2;
}

/* On narrow screens center text, on wider screens put it to the left */
.carousel li {
    text-align: center;
    font-size: 100%;
}
@media (min-width: 768px) {
    .carousel li {
        text-align: left;
        font-size: 200%;
    }
}
@media (min-width: 960px) {
    .carousel li {
        text-align: left;
        font-size: 220%;
    }
}
