.distribution-plot {
    /* Constriain the tick positions */
    position: relative;
    line-height: 0;
}

.distribution-plot > .plot {
    position: relative;
    width: 100%;
    overflow: hidden;
    border: 1px solid #666;
    border-radius: 2px 2px 2px 0;
    background-color: #ccc;
}

.distribution-plot > .plot > a > rect {
    fill: #f9f9f9;
}
.distribution-plot > .plot.min-width-5 > a > rect {
    stroke-width: 1;
    stroke: rgba(80,80,80,.3);
}

.distribution-plot > .plot .kwic-highlight-2 { fill: hsl( 70, 92%, 50%); }
.distribution-plot > .plot .kwic-highlight-3 { fill: hsl(166, 92%, 50%); }
.distribution-plot > .plot .kwic-highlight-4 { fill: hsl(264, 92%, 50%); }
.distribution-plot > .plot .kwic-highlight-1 { fill: hsl(304, 92%, 50%); }
.distribution-plot > .plot > a > rect:hover {
    fill: rgba(255, 160, 51, 0.7);
    stroke: hsl(32, 72%, 44%);
}

.distribution-plot > .tick {
    display: block;
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;

    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #666;
}
.distribution-plot > .tick:hover {
    border-top-color: #ffa033;
}
