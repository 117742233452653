#content .data-version {
    position: absolute;
    z-index: 1;
    margin: 3px;
    cursor: help;
    font-size: smaller;
    color: #666;
}

#content .dataTables_info {
    padding: 5px 1em;
}
#content table.dataTable {
    border-collapse: separate;
}
#content table.dataTable th {
    padding-top: 2em;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
    background: #eee;
    text-align: center;
}
#content table.dataTable td {
    border-bottom: 1px solid #ddd;
}
#content table.dataTable>tbody>tr:last-child>td {
    border-bottom: none;
}

#content table.dataTable tr>*:first-child {
    padding-left: 1em;
}
#content table.dataTable tr>*:last-child {
    padding-right: 1em;
}

#content table.dataTable>tbody>tr {
    cursor: pointer;
}
#content table.dataTable>tbody>tr { background: #ffffff; }
#content table.dataTable>tbody>tr.odd { background: #f9f9f9; }
#content table.dataTable>tbody>tr.kwic-highlight-2 { background: #e6f5c9; }
#content table.dataTable>tbody>tr.kwic-highlight-3 { background: #b3e2cd; }
#content table.dataTable>tbody>tr.kwic-highlight-4 { background: #cbd5e8; }
#content table.dataTable>tbody>tr.kwic-highlight-1 { background: #f4cae4; }
#content table.dataTable>tbody>tr.selected {
    background: repeating-linear-gradient(
        -45deg,
        rgba(240, 220,  78, 0.4),
        rgba(240, 220,  78, 0.4) 10px,
        rgba(240, 220,  78, 0.3) 10px,
        rgba(240, 220,  78, 0.3) 20px
    );
}
#content table.dataTable>tbody>tr:not(.child):hover {
    background: rgba(70, 74, 76, 0.3);
}
#content table.dataTable>tbody>tr.selected:not(.child):hover {
    background: repeating-linear-gradient(
        -45deg,
        rgba(70, 74, 76, 0.4),
        rgba(70, 74, 76, 0.4) 10px,
        rgba(70, 74, 76, 0.3) 10px,
        rgba(70, 74, 76, 0.3) 20px
    );
}

#content table.dataTable.hasTagColumns th {
    padding-top: 70px;
}
#content table.dataTable th.tagColumn {
    position: relative;
}
#content table.dataTable th.tagColumn>* {
    position: absolute;
    z-index: 1;
    right: 75%;
    bottom: 4px;
    max-width: 60px;
    transition: max-width 0.3s ease;
    transition-delay: 0.3s;
    line-height: 1.5rem;
    overflow: hidden;
    transform: rotate(90deg);
    transform-origin: 100% 100%;
    background: inherit;
    white-space: nowrap;
    direction: rtl;
}
#content table.dataTable th.tagColumn:hover>* {
    max-width: 200px;
}

table.dataTable thead > tr > th:active,
table.dataTable thead > tr > td:active {
  outline: none;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc_disabled {
  cursor: pointer;
  position: relative;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  position: absolute;
  top: 3px;
  right: 3px;
  display: block;
  opacity: 0.8;
  font-size: 1.5rem;
}
#content table.dataTable thead .sorting:hover,
#content table.dataTable thead .sorting_asc:hover,
#content table.dataTable thead .sorting_desc:hover,
#content table.dataTable thead .sorting_asc_disabled:hover,
#content table.dataTable thead .sorting_desc_disabled:hover {
    background: #ccc;
}
table.dataTable thead .sorting:after {
  opacity: 0.4;
  content: "\21c5"; /* ⇅ */
  font-weight: normal
  /* sort */
}
table.dataTable thead .sorting_asc:after {
  content: "\21ca"; /* ⇊ */
  font-weight: bold;
  /* sort-by-attributes */
}
table.dataTable thead .sorting_desc:after {
  content: "\21c8"; /* ⇈ */
  font-weight: bold;
  /* sort-by-attributes-alt */
}
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:after {
  color: #eee;
}

#content table.dataTable.metadata-hidden .metadataColumn {
    display: none;
}

#content table.dataTable td.numeric {
    text-align: right;
    font-variant-numeric: lining-nums tabular-nums;
}

#content table.dataTable td mark {
    background: rgba(200, 200, 200, 0.33);
    color: inherit;
    padding: 0 1px;
}
#content table.dataTable.metadata-hidden td mark {
    background: transparent;
}
#content table.dataTable td.context > div.l.m1 > mark:nth-of-type(0n+1),
#content table.dataTable td.context > div.l.m2 > mark:nth-of-type(0n+2),
#content table.dataTable td.context > div.l.m3 > mark:nth-of-type(0n+3),
#content table.dataTable td.context > div.l.m4 > mark:nth-of-type(0n+4),
#content table.dataTable td.context > div.l.m5 > mark:nth-of-type(0n+5),
#content table.dataTable td.context > div.l.m6 > mark:nth-of-type(0n+6),
#content table.dataTable td.context > div.l.m7 > mark:nth-of-type(0n+7),
#content table.dataTable td.context > div.l.m8 > mark:nth-of-type(0n+8),
#content table.dataTable td.context > div.l.m9 > mark:nth-of-type(0n+9),
#content table.dataTable td.context > div.l.m10 > mark:nth-of-type(0n+10),
#content table.dataTable td.context > div.r.m1 > mark:nth-last-of-type(0n+1),
#content table.dataTable td.context > div.r.m2 > mark:nth-last-of-type(0n+2),
#content table.dataTable td.context > div.r.m3 > mark:nth-last-of-type(0n+3),
#content table.dataTable td.context > div.r.m4 > mark:nth-last-of-type(0n+4),
#content table.dataTable td.context > div.r.m5 > mark:nth-last-of-type(0n+5),
#content table.dataTable td.context > div.r.m6 > mark:nth-last-of-type(0n+6),
#content table.dataTable td.context > div.r.m7 > mark:nth-last-of-type(0n+7),
#content table.dataTable td.context > div.r.m8 > mark:nth-last-of-type(0n+8),
#content table.dataTable td.context > div.r.m9 > mark:nth-last-of-type(0n+9),
#content table.dataTable td.context > div.r.m10 > mark:nth-last-of-type(0n+10) {
    background: rgba(255, 59, 0, 0.45);
}

#content table.dataTable td {
    padding-top: 6px;
    padding-bottom: 6px;
}
#content table.dataTable td.context {
    position: relative;
    max-width: 20vw;
    vertical-align: top;
    padding-right: 0.3rem;
    overflow: hidden; /* Ideally would be -x, but FF ignores */
    background: inherit;
    white-space: nowrap;
}
#content table.dataTable td.context > div.r {
    float: right;
}
#content table.dataTable td.context.node {
    padding-left: 2rem;
    text-indent: -1rem;
    background-color: rgba(133, 159, 170, 0.2);
    white-space: initial;
}
#content table.dataTable.narrow-node td.context.node {
    width: 3rem;
    white-space: nowrap;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    text-indent: 0;
}
#content table.dataTable td.context.right {
    vertical-align: bottom;
    padding-left: 1.3rem;
    text-indent: -1rem;
}
#content table.dataTable td.context.left:after,
#content table.dataTable td.context.right:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    width: 5px;
    pointer-events: none;
    background: inherit;
    opacity: 0.7;
}
#content table.dataTable td.context.right:after {
    left: auto;
    right: 0;
}

#content table.dataTable td.tagColumn {
    padding: 0;
    text-align: center;
    font-size: 1.5rem;
}
#content table.dataTable td.nowrapColumn {
    white-space: nowrap;
}
#content table.dataTable a.bookLink:hover line {
    stroke: #ffa033;
}
#content .dataTables_paginate {
    display: flex;
    text-align: center;
    border-top: 1px solid #666;
}
#content .dataTables_paginate>span {
    display: flex;
    flex: 1 0 auto;
}
#content .dataTables_paginate>span>.ellipsis {
    flex: 1 0 auto;
}
#content .dataTables_paginate>span>.ellipsis,
#content .dataTables_paginate a {
    padding: 1em;
    border-right: 1px solid #666;
    background: #eee;
    cursor: pointer;
}
#content .dataTables_paginate a.current {
    color: #ffa033;
    font-weight: bold;
}
#content .dataTables_paginate>a:last-child {
    border-left: 1px solid #666;
    border-right: none;
}

#content table.dataTable tr.child > td > div {
    overflow: auto;
    max-height: 0;
    transition: max-height 0.3s ease;
}
#content table.dataTable tr.child.expand > td > div {
    max-height: 10rem;
}
