#content>.book-content {
  max-width: 960px;
  margin: auto;
  padding: 10px;
  font-family: serif;
  font-size: 1.1em;
}

.book-content span.metadata-title {
  font-size: 3rem;
}

.book-content span.metadata-author {
  font-size: 2rem;
}

.book-content span.chapter-part,
.book-content span.chapter-title {
  font-size: 1.7rem;
}

nav fieldset[data-name="text"] label span {
  padding: 0 0.3rem;
  line-height: 1.4em;
}

nav fieldset[data-name="text"] label.chapter-sentence input + span {
  margin-left: -2px;
}
nav fieldset[data-name="text"] label.chapter-sentence input:checked + span {
  border: 1px solid #999;
  border-radius: 2px;
  background: #eee;
}
.book-content.h-chapter-sentence span.chapter-sentence {
  border-top: 1px solid #999;
  border-bottom: 1px solid #999;
  background: #eee;
}
.book-content.h-chapter-sentence span.boundary-sentence {
  border-right: 1px solid #999;
}

nav fieldset[data-name="text"] label.quote-quote input:checked + span,
.book-content.h-quote-quote span.quote-quote {
  background: hsl(187, 100%, 74%);
}

nav fieldset[data-name="text"] label.quote-suspension-short input:checked + span,
.book-content.h-quote-suspension-short span.quote-suspension-short {
  background: hsl(70, 100%, 74%);
}

nav fieldset[data-name="text"] label.quote-suspension-long input:checked + span,
.book-content.h-quote-suspension-long span.quote-suspension-long {
  background: hsl(29, 100%, 74%);
}

nav fieldset[data-name="text"] label.quote-embedded input:checked + span,
.book-content.h-quote-embedded span.quote-embedded {
  background: hsl(123, 100%, 74%);
}

.book-content span.highlight {
  background: yellow !important;
  font-weight: bold;
}
